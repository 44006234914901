
import {prefix} from './prefix';

export const deleteUser = async (user, token) => {
    return fetch(`${prefix}/user`, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(user)
    });
};
