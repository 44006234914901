
import {
  ROOM_SET_ROOM,
  ROOM_SET_SPOT,
  ROOM_SET_MATRIX_VALUE,
  ROOM_INIT_MATRIX
} from '../action-types';

class Matrix {
  constructor(m) {
    this._values = new Map(m);
  }
  keyFor(x, y) {
    return [x, y].join(':')
  }
  get(x, y) {
    const key = this.keyFor(x, y);
    const record = this._values.get(key);
    return record?.value;
  }
  set(x, y, value) {
    const key = this.keyFor(x, y);
    this._values.set(key, {
      x, y,
      value
    });
    return new Matrix(this._values);
  }
  delete(x, y) {
    const key = this.keyFor(x, y);
    this._values.delete(key);
    return new Matrix(this._values);
  }
  toArray() {
    const it = this.entries();
    const ary = [];
    for (const entry of it) {
      ary.push(entry[1]);
    }
    return ary;
  }
  entries() {
    return this._values.entries();
  }
}

const initMatrix = layout => {
  const matrix = new Matrix();
  for (let y = 0; y < layout.length; y += 1) {
    for (let x = 0; x < layout[y].length; x += 1) {
      matrix.set(x, y, layout[y][x]);
    }
  }
  return matrix;
};

const initialState = {
  room: {
    rows: 3,
    columns: 8
  },
  matrix: new Matrix()
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROOM_SET_SPOT:
      console.log(action.payload.spot);
      return {
        ...state,
        spot: action.payload.spot
      };
    case ROOM_SET_ROOM:
      return {
        ...state,
        room: action.payload.room
      }
    case ROOM_SET_MATRIX_VALUE:
      return {
        ...state,
        matrix: state.matrix.set(
          action.payload.x,
          action.payload.y,
          action.payload.value
        )
      };
    case ROOM_INIT_MATRIX:
      return {
        ...state,
        matrix: initMatrix(action.payload.layout)
      };
    default:
      return state
  }
}