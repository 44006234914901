
import * as api from '../../api';
import {
  ROOM_SET_SPOT,
  ROOM_SET_ROOM,
  ROOM_INIT_MATRIX,
  ROOM_SET_MATRIX_VALUE
} from '../action-types';
import { notifySuccess, notifyError } from './notification';

export const setRoom = room => {
  return {
    type: ROOM_SET_ROOM,
    payload: { room }
  }
};

export const setRoomMatrixValue = (x, y, value) => {
  return {
    type: ROOM_SET_MATRIX_VALUE,
    payload: { x, y, value }
  }
};

export const initRoomMatrix = layout => {
  return {
    type: ROOM_INIT_MATRIX,
    payload: { layout }
  }
};

export const setRoomSpot = spot => {
  return {
    type: ROOM_SET_SPOT,
    payload: { spot }
  }
};

export const fetchRoom = () => {

  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const response = await api.fetchRoom(token);
    const data = await response.json();
    if (response.ok) {
      dispatch(setRoom({
        rows: data.room.rows,
        columns: data.room.columns
      }));
      dispatch(initRoomMatrix(data.room.layout));
    }
  };
};

/**
 * Save room
 * @param {*} room 
 * @param {*} layout 
 */
export const saveRoom = (room, layout) => {

  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const response = await api.updateRoom(token,
      room.rows, room.columns, layout);
    if (response.ok) {
      return dispatch(notifySuccess('Room was saved'));
    }
    const data = await response.json();
    dispatch(notifyError(data.message));
  };
};

/**
 * Fetch room spot
 * @param {*} sessionId
 * @param {*} x
 * @param {*} y
 */
export const fetchRoomSpot = (sessionId, x, y) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const response = await api.fetchRoomSpot(token, sessionId, x, y);
    const data = await response.json();
    if (response.ok) {
      dispatch(setRoomSpot(data.spot));
    }
  };
};