
import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { Link } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import clsx from 'clsx';
import { Logo } from './logo';
import css from './navigation.module.css';

export const Navigation = ({ pathname }) => {

  const doShowClose = ['/booking', '^/session', '/payment'].some(p => {

    return RegExp(p).test(pathname);
  });

  const doShowLogout = ['/profile', /^\/$/].some(p => {
    return RegExp(p).test(pathname);
  });

  const containerClassName = clsx(css.flexContainer,
    doShowClose && css.closeState);

  return (
    <Grid container alignItems="center"
      className={containerClassName}
    >
      <Grid item xs={4}>
        <div className={css.closeMobile}>
          <Link to='/profile' style={{
            color: 'inherit',
            textDecoration: 'none'
          }}>
            <KeyboardBackspaceIcon fontSize="large" />
          </Link>
        </div>
      </Grid>
      <Grid item xs={4} className={css.logo}>
        <Link to='/'>
          <Logo />
        </Link>
      </Grid>
      <Grid item xs={4} className={css.profile}>
        {(() => {
          if (!doShowLogout) {
            return null;
          }
          return <Link to='/logout' style={{
            color: '#615D5D',
            textDecoration: 'underline',
            textTransform: 'uppercase'
          }}>Logout</Link>
        })()}
        <div className={css.closeDesktop}>
          <Link to='/profile' style={{
            color: '#615D5D', textDecoration: 'underline'
          }}>
            CLOSE <CloseIcon />
          </Link>
        </div>
      </Grid>
    </Grid>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string
};

export default function NavigationWrapper() {
  return (
    <Location>
      {({ location }) => {
        return <Navigation pathname={location.pathname} />
      }}
    </Location>
  );
}
