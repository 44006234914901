
import { createState, createReducer } from './base';

const initialState = {
  error: null,
  status: 'idle' // idle | creating | create-success | create-error
};

export default createReducer(initialState, [{
  type: 'FOO',
  h: (prevState, action) => {
    return createState(prevState, {
      foo: action.payload.foo
    })
  }
}, {
  type: 'BAR',
  h: (prevState, action) => {
    return createState(prevState, {
      bar: action.payload.bar
    })
  }
}]);
