
import { prefix } from './prefix';

export const createWhitelisting = async (token, data) => {
  const kind = data.domain ? 'domain' : 'email';
  return fetch(prefix + '/whitelist/' + kind, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    },
    body: JSON.stringify(data)
  });
};