
import { prefix } from './prefix';

export const fetchRoomSpot = async (token, sessionId, x, y) => {

  return fetch(prefix + `/rooms/1/${sessionId}/${x}/${y}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};
