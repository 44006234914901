import { logger } from '../../utils/log';
import * as api from '../../api';
import { fetchSession } from './session';

import {
  RESERVATION_CREATE_ERROR,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_RESET,
  RESERVATION_SET_CURRENT,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_ERROR,
  RESERVATION_CLEAR_DELETED
} from '../action-types';

export const createReservationSuccess = () => {
  return {
    type: RESERVATION_CREATE_SUCCESS,
    payload: { createSuccess: true }
  }
};

export const reservationReset = () => {
  return {
    type: RESERVATION_RESET
  }
};

export const reservationCreateError = (error) => {
  return {
    type: RESERVATION_CREATE_ERROR,
    payload: { error }
  }
};

export const setCurrentReservation = (session, spot) => {
  return {
    type: RESERVATION_SET_CURRENT,
    payload: {
      reservation: { session, spot }
    }
  }
};

export const createReservation = (session, spot) => {

  return async (dispatch, getState) => {

    const state = getState();
    const { token, id } = state.auth;

    try {

      const response = await api.createReservation(
        session.id, id, [spot.x,spot.y], token);

      if (response.ok) {

        dispatch(createReservationSuccess());
        dispatch(fetchSession(session.id));
      } else {

        const error = await response.json();
        dispatch(reservationCreateError(error));
      }
    } catch (err) {

      logger.error(err);
    }
  };
};

export const clearDeletedReservation = () => ({
  type: RESERVATION_CLEAR_DELETED
});

export const deleteReservationSuccess = (reservation) => ({
  type: RESERVATION_DELETE_SUCCESS,
  payload: { reservation }
});

export const deleteReservationError = (error) => ({
  type: RESERVATION_DELETE_ERROR,
  payload: { error }
});

export const deleteReservation = (reservation) => {

  return async (dispatch, getState) => {

    const state = getState();
    const { token } = state.auth;

    try {
      const response = await api.deleteReservation(reservation.id, token);
      if (response.ok) {
        const result = await response.json();
        const r = result.deleted ? reservation : null;
        dispatch(deleteReservationSuccess(r));
      }
      else {
        const error = await response.json();
        dispatch(deleteReservationError(error));
      }
    }
    catch (err) {
      logger.error(err);
    }
  };
};