
import {
  AUTH_SET_TOKEN, AUTH_SET_EMAIL,
  AUTH_AUTHENTICATION_FAIL, AUTH_READ_TOKEN,
  AUTH_ERROR, AUTH_ERROR_CLEAR,
  AUTH_RESET, AUTH_LOGOUT,
  AUTH_PASSWORD_RESET_SUCCESS,
  AUTH_PASSWORD_CHANGE_SUCCESS,
  AUTH_SET_STATUS
} from '../action-types';

const setStatus = status => {

  const statuses = [
    'idle', 'resetting', 'reset-success', 'change-success', 'error'
  ];

  const idx = statuses.indexOf(status);

  if (idx > -1) {
    return statuses[idx];
  }
  return 'idle';
};

const initialState = {
  token: null,
  id: null,
  tokenIsRead: false,
  error: null,
  expires: {
    r: null, t: null
  },
  status: setStatus('idle')
};

export default function (state = initialState, action) {
  switch (action.type) {

    case AUTH_SET_TOKEN:
      return {
        token: action.payload.token,
        id: action.payload.id, // ~profileId
        expires: {
          t: new Date(Date.now() + action.payload.expiresIn.t),
          r: new Date(Date.now() + action.payload.expiresIn.r)
        },
        error: null
      };

    case AUTH_SET_EMAIL:
      return { ...state, email: action.payload.email };

    case AUTH_ERROR:
      return { ...state, error: action.payload.error };

    case AUTH_ERROR_CLEAR:
      return { ...state, error: null };

    case AUTH_READ_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        id: action.payload.id,
        expires: {
          t: new Date(Date.now() + action.payload.expiresIn.t),
          r: new Date(Date.now() + action.payload.expiresIn.r)
        },
        tokenIsRead: true
      };

    case AUTH_AUTHENTICATION_FAIL:
      return { token: null };

    case AUTH_RESET:
      return {
        ...state,
        token: null,
        id: null,
        tokenIsRead: false
      };

    case AUTH_LOGOUT:
      return initialState;

    case AUTH_SET_STATUS:
      return {
        ...state,
        status: setStatus(action.payload.status)
      };

    case AUTH_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        status: setStatus('reset-success')
      };

    case AUTH_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        status: setStatus('change-success')
      };

    default:
      return state;
  }
}
