
import { createState, createReducer } from './base';
import {
  INSTRUCTORS_SET,
  INSTRUCTOR_SET_ERROR
} from '../action-types';

const initialState = {
  instructors: []
};

export default createReducer(initialState, [{
  type: INSTRUCTORS_SET,
  h: (state, action) => createState(state, {
    instructors: action.payload.instructors
  })
}, {
  type: INSTRUCTOR_SET_ERROR,
  h: (state, action) => createState(state, {
    error: action.payload.error
  })
}]);

