import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export const Spinning = ({ text, timeout, fallback }) => {

  const [node, setNode] = useState();
  
  useEffect(() => {
    if (timeout) {
      
      const _id = setTimeout(() => {
        setNode(fallback);
      }, timeout);
      return () => {
        clearTimeout(_id);
      }
    }
  }, [fallback, timeout]);

  if (node) {
    return (
      <Box m={4}>
        <div style={{
          padding: '20px',
          textAlign: 'center'
        }}>
          {node}
        </div>
      </Box>
    );
  }

  return (
    <Box m={4}>
      <div style={{
        padding: '20px',
        textAlign: 'center'
      }}>
        <div>
          <CircularProgress color='primary' />
        </div>
        <div style={{ marginTop: '20px' }}>
          {text}
        </div>
      </div>
    </Box>
  );
};

Spinning.propTypes = {
  text: PropTypes.string,
  timeout: PropTypes.number,
  fallback: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
