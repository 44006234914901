import { prefix } from './prefix';

export const updateRoom = (token, rows, columns, layout) => {

  return fetch(prefix + '/rooms/1', {
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rows, columns, layout
    })
  });
};