
import * as actionTypes from '../action-types';

const getStatus = (s) => {

  const statuses = ['idle', 'loading',
    'creating', 'create-error',
    'create-success', 'error'];
  if (statuses.includes(s)) {
    return s;
  }
  return 'idle';
};

const initialState = {
  posts: [],
  post: {
    title: '',
    content: ''
  },
  status: getStatus('idle')
};

export default function (state = initialState, action) {

  switch (action.type) {

    case actionTypes.POST_SET_STATUS:
      return {
        ...state,
        status: getStatus(action.payload.status)
      };

    case actionTypes.POST_SET_POSTS:
      return {
        ...state,
        posts: action.payload.posts
      };

    case actionTypes.POST_SET_POST:
      return {
        ...state,
        post: action.payload.post
      };

    case actionTypes.POST_SET_ERROR:
      return {
        ...state,
        error: action.payload.error
      };

    case actionTypes.POST_RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
