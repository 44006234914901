
import {
  CALENDAR_SET_CURRENT_DATE,
  CALENDAR_CLEAR_CURRENT_DATE,
  CALENDAR_SET_VIEW,
  CALENDAR_TOGGLE_VIEW
} from '../action-types';

const initialState = {
  currentDate: new Date(),
  view: 'week'
};

export default function (state = initialState, action) {

  switch (action.type) {
    case CALENDAR_SET_CURRENT_DATE:
      return { ...state, currentDate: action.payload.date };
    case CALENDAR_CLEAR_CURRENT_DATE:
      return { ...state, currentDate: new Date() };
    case CALENDAR_SET_VIEW:
      return { ...state, view: action.payload.view };
    case CALENDAR_TOGGLE_VIEW:
      return { ...state,
        view: state.view === 'week' ? 'month' : 'week'
      };
    default:
      return state;
  }
}