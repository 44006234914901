
import {
  SCHEDULE_SET,
  SCHEDULE_DRAIN_SESSIONS
} from '../action-types';

const initialState = {
  error: null,
  sessions: []
};

const setSessions = (state, schedule) => {

  const sessions = schedule.sessions.map(session => {
    session.available_spots = session.room_layout.map((row) => {
      return row.filter(cell => cell.kind);
    }).reduce((acc, cur) => {
      return acc.concat(cur);
    }, []).length - session.reservations.length;
    return session;
  });

  return {
    ...state,
    sessions
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_SET:
      return setSessions(
        state,
        action.payload.schedule
      );
    case SCHEDULE_DRAIN_SESSIONS:
      return {
        ...state,
        sessions: []
      };
    default:
      return state;
  }
}
