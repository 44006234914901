
import { prefix } from './prefix';

export const fetchUsers = async (token) => {
  return fetch(`${prefix}/user/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
