
const Logger = () => {

  const logOn = process?.env?.REACT_APP_LOG_ON;
  const ctx = {};

  for (const fn in console) {
    if (typeof console[fn] !== 'function') {
      continue;
    }
    ctx[fn] = (...args) => {
      if (logOn) {
        console[fn].call(console, ...args);
      }
    };
  }
  ctx.info('Init logger at', new Date());
  return ctx;
};

export const logger = Logger();
