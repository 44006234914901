
import * as api from '../../api';
import { logout } from './auth';

import {
  PROFILE_CREATE_PROFILE_ERROR,
  PROFILE_RESET_STATE,
  PROFILE_SET,
  PROFILE_SET_BALANCE,
  PROFILE_SET_RESERVATIONS,
  PROFILE_SET_STATUS
} from '../action-types';

export const createProfileError = (error) => ({
  type: PROFILE_CREATE_PROFILE_ERROR,
  payload: { error }
});

export const setProfileStatus = status => ({
  type: PROFILE_SET_STATUS,
  payload: { status }
});

export const resetProfileState = () => ({
  type: PROFILE_RESET_STATE
});

export const createProfile = (profile) => {
  return async dispatch => {
    dispatch(setProfileStatus('create-profile'));
    const response = await api.createProfile(profile);
    if (response.ok) {
      dispatch(setProfileStatus('create-profile-success'))
    } else {
      const data = await response.json();
      dispatch(setProfileStatus('create-profile-error'));
      dispatch(createProfileError(data.error))
    }
  };
};

export const confirmProfile = (email, access_code) => {
  return async dispatch => {
    dispatch(setProfileStatus('confirm-profile'));
    const response = await api.confirmProfile(email, access_code);
    if (response.ok) {
      dispatch(setProfileStatus('confirm-profile-success'));
    } else {
      const data = await response.json();
      dispatch(setProfileStatus('confirm-profile-error'));
      dispatch(createProfileError(data.error));
    }
  };
};

export const setProfile = (profile) => {
  return {
    type: PROFILE_SET,
    payload: { profile }
  }
};

export const setProfileBalance = (balance) => {
  return {
    type: PROFILE_SET_BALANCE,
    payload: { balance }
  }
};

export const setProfileReservations = (reservations) => {
  return {
    type: PROFILE_SET_RESERVATIONS,
    payload: { reservations }
  }
};

export const fetchProfile = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token, id } = state.auth;
    const response = await api.fetchProfile(id, token);
    if (response.ok) {
      const data = await response.json();
      const { profile, balance, reservations } = data;
      dispatch(setProfile(profile));
      dispatch(setProfileBalance(balance));
      dispatch(setProfileReservations(reservations));
    } else {
      dispatch(logout());
    }
  };
};