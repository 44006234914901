
import { logger } from '../../utils/log';
import * as api from '../../api';
import {
  SESSION_SET,
  SESSIONS_SET,
  SESSION_SET_SPOT,
  SESSION_CREATE_SUCCESS,
  SESSION_UPDATE_SUCCESS,
  SESSION_DELETE_SUCCESS,
  SESSION_SUSPEND_SUCCESS,
  SESSION_SET_ERROR,
  SESSION_SET_STATUS
} from '../action-types';

export const setSession = (session) => {
  return {
    type: SESSION_SET,
    payload: { session }
  }
};

export const setSessions = (sessions) => {
  return {
    type: SESSIONS_SET,
    payload: { sessions }
  }
};

export const setSessionSpot = (spot) => {
  return {
    type: SESSION_SET_SPOT,
    payload: { spot }
  };
};

export const fetchSession = (sessionId) => {
  return async dispatch => {

    dispatch(setSession(null));

    const response = await api.fetchSession(sessionId);
    if (response.ok) {
      const data = await response.json();
      const session = data.session;
      dispatch(setSession(session));
    }
    else {
      logger.log(response);
    }
  };
};

export const fetchSessionsWithReservationsByDateRange = (from, to) => {

  return async (dispatch, getState) => {

    dispatch(setSessionStatus('loading'));
    const state = getState();
    const { token } = state.auth;

    const response = await api.fetchSessionsWithReservationsByDateRange(
      token, from, to);

    if (response.ok) {
      const data = await response.json();
      dispatch(setSessions(data.sessions));
      dispatch(setSessionStatus('idle'));
    }
  };
};

export const createSessionSuccess = (contentLocation) => ({
  type: SESSION_CREATE_SUCCESS,
  payload: {
    contentLocation
  }
});

export const updateSessionSuccess = (contentLocation) => ({
  type: SESSION_UPDATE_SUCCESS,
  payload: {
    contentLocation
  }
});

export const deleteSessionSuccess = () => {
  return {
    type: SESSION_DELETE_SUCCESS
  }
};

export const suspendSessionSuccess = () => {
  return {
    type: SESSION_SUSPEND_SUCCESS
  }
};

export const setSessionStatus = (status) => {
  return {
    type: SESSION_SET_STATUS,
    payload: { status }
  }
};

export const setSessionError = (error, status) => ({
  type: SESSION_SET_ERROR,
  payload: { error, status }
});

export const updateSession = session => {

  return async (dispatch, getState) => {
    dispatch(setSessionStatus('updating'));
    const state = getState();
    const { token } = state.auth;
    const response = await api.updateSession(token, session);
    if (response.ok) {
      dispatch(updateSessionSuccess(
        response.headers.get('content-location')
      ));
    }
    else {
      const error = await response.json();
      dispatch(setSessionError(error), 'update-error');
    }
  };
};

export const createSession = (session) => {

  return async (dispatch, getState) => {
    dispatch(setSessionStatus('creating'));
    const state = getState();
    const { token } = state.auth;
    const response = await api.createSession(token, session);
    if (response.ok) {
      dispatch(createSessionSuccess(
        response.headers.get('content-location')
      ));
    }
    else {
      const error = await response.json();
      dispatch(setSessionError(error, 'create-error'));
    }
  };
};

export const deleteSession = (session) => {

  return async (dispatch, getState) => {

    dispatch(setSessionStatus('deleting'));

    const state = getState();
    const { token } = state.auth;

    const response = await api.deleteSession(token, session.id);

    if (response.ok) {
      dispatch(deleteSessionSuccess());
    }
    else {
      const error = await response.json();
      dispatch(setSessionError(error, 'delete-error'));
    }
  };
};

export const suspendSession = (session) => {

  return async (dispatch, getState) => {

    dispatch(setSessionStatus('suspending'));
    const state = getState();
    const { token } = state.auth;
    const response = await api.suspendSession(token, session.id);

    if (response.ok) {
      dispatch(suspendSessionSuccess());
    }
    else {
      const error = await response.json();
      dispatch(setSessionError(error, 'suspend-error'));
    }
  };
};