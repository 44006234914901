
export const createReservation = (session_id, user_id, spot, token) => {
  return fetch('/api/reservations', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      session_id, user_id, spot
    })
  });
};
