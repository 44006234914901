
import { prefix } from './prefix';

export const fetchWhitelist = async (token) => {

  return fetch(prefix + '/whitelist', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    }
  });
};