import { prefix } from './prefix';

export const refreshToken = async () => {

  const response = await fetch(prefix + '/refresh-token', {
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });

  if (!response.ok) {
    const error = await response.json();
    throw error;
  }

  const data = await response.json();
  return data;
};
