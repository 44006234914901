import { prefix } from './prefix';

export const logout = async token => {

  const response = await fetch(prefix + '/logout', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    }
  });

  if (!response.ok) {
    const json = await response.json();
    throw json;
  }
  console.log(response);
};
