
import { logger } from '../utils/log';

/**
 * fetchSchedule
 * offset and limit is by number of 'days'
 * @param {*} offset
 * @param {*} limit
 */
export const fetchSchedule = async (offset = 0, limit = 1) => {
  const query = `?offset=${offset}&limit=${limit}`;
  const response = await fetch('/api/schedule' + query);
  if (response.ok) {
    const data = await response.json();
    return data.schedule;
  } else {
    logger.log(response);
  }
};

/**
 * fetchScheduleByDate - fetches schedule from date with 
 * @param {*} startFromDate date object
 * @param {*} limit days
 */
export const fetchScheduleByDate = async (startFromDate, limit = 1) => {

  const query = `?from=${startFromDate.toISOString()}&limit=${limit}`;
  const response = await fetch('/api/schedule' + query);

  if (response.ok) {

    const data = await response.json();
    return data.schedule;
  }
  else {

    logger.log(response);
  }
};