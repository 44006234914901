
import { prefix } from './prefix';

export const createPost = async (token, post) => {
  return fetch(prefix + '/posts', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      title: post.title,
      content: post.content,
      kind: post.kind
    })
  });
};