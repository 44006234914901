import { prefix } from './prefix';

export const passwordChange = ({
  email, password, password_confirmation, access_code
}) => {
  return fetch(prefix + '/password-change', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      email, password, password_confirmation, access_code
    })
  })
};