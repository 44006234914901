
import {
  CALENDAR_SET_CURRENT_DATE,
  CALENDAR_CLEAR_CURRENT_DATE,
  CALENDAR_SET_VIEW,
  CALENDAR_TOGGLE_VIEW
} from '../action-types';

export const setCurrentDate = date => {
  return {
    type: CALENDAR_SET_CURRENT_DATE,
    payload: { date }
  }
};

export const clearCurrentDate = () => ({
  type: CALENDAR_CLEAR_CURRENT_DATE
});

export const calendarToggleView = () => ({
  type: CALENDAR_TOGGLE_VIEW
});

export const setCalendarView = view => {
  return {
    type: CALENDAR_SET_VIEW,
    payload: { view }
  }
}