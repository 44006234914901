import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from "./reducers";

const enhancer = typeof window === 'object' &&
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

function catchHooks(hooks) {
  return function ({ dispatch }) {
    return next => action => {
      
      // Call the next dispatch method in the middleware chain.
      const returnValue = next(action);
      // call any hook listening in on this action
      const hook = hooks[action.type];
      if (typeof hook === 'function') {
        hook(dispatch);
      }
      // This will likely be the action itself, unless
      // a middleware further in chain changed it.
      return returnValue;
    }
  }
}

const hooks = {
  'PHONY': () => {
    // dispatch(callAction());
  }
};

export default createStore(
  rootReducer,
  enhancer(
    applyMiddleware(thunkMiddleware, catchHooks(hooks))
  )
);
