
import { prefix } from './prefix';

export const confirmProfile = async (email, access_code) => {
  return fetch(prefix + '/confirm', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({ email, access_code })
  });
};