
import * as api from '../../api';
import { notifyInfo, notifyError } from './notification';

import {
  AUTH_SET_TOKEN, AUTH_RESET,
  AUTH_READ_TOKEN, AUTH_SET_EMAIL,
  AUTH_ERROR, AUTH_LOGOUT,
  AUTH_PASSWORD_RESET_SUCCESS,
  AUTH_PASSWORD_CHANGE_SUCCESS, AUTH_SET_STATUS
} from '../action-types';

export const setToken = (token, id, expiresIn) => ({
  type: AUTH_SET_TOKEN,
  payload: { token, id, expiresIn }
});

export const readToken = () => {

  return async (dispatch) => {
    try {
      const data = await api.refreshToken();
      const { token, id, expiresIn } = data;
      dispatch({
        type: AUTH_READ_TOKEN,
        payload: {
          token, id,
          expiresIn
        }
      })
    } catch (error) {
      dispatch({
        type: AUTH_READ_TOKEN,
        payload: {
          token: null, id: null,
          expiresIn: {
            t: 0, r: 0
          }
        }
      });
      dispatch(setAuthError(error));
    }
  };
};

export const setEmail = email => ({
  type: AUTH_SET_EMAIL,
  payload: { email }
});

export const clearToken = () => {
  return {
    type: AUTH_RESET,
  }
};

export const setAuthError = (error) => {
  return {
    type: AUTH_ERROR,
    payload: { error }
  }
};

export const authenticate = ({ username, password }) => {

  return async dispatch => {

    try {
      const data = await api.authenticate(username, password);
      const { token, id, expiresIn } = data;
      dispatch(setToken(token, id, expiresIn));
      dispatch(notifyInfo('You were successfully logged in'));
    } catch (err) {
      dispatch(setAuthError(err));
      dispatch(notifyError(
        'Could not log in. Please check your credentials.'));
    }
  };
};

export const logout = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    try {
      await api.logout(token);
      dispatch({
        type: AUTH_LOGOUT
      })
    } catch (err) {
      console.log(err);
    }
  };
};

export const setAuthStatus = (status) => ({
  type: AUTH_SET_STATUS,
  payload: { status }
});

export const passwordReset = email => {

  return async dispatch => {
    // we set this to 'resetting' because a round trip
    // to sendgrid takes noticable time
    dispatch(setAuthStatus('resetting'));
    const response = await api.passwordReset(email);
    const payload = await response.json();
    if (response.ok) {
      dispatch(setEmail(email));
      return dispatch({
        type: AUTH_PASSWORD_RESET_SUCCESS
      });
    }
    return dispatch(setAuthError(payload));
  };
};

export const passwordChange = ({ email, password, password_confirmation, access_code }) => {
  return async dispatch => {
    const response = await api.passwordChange({
      email, password, password_confirmation, access_code
    });
    const payload = await response.json();
    if (response.ok) {
      return dispatch({
        type: AUTH_PASSWORD_CHANGE_SUCCESS
      });
    }
    return dispatch(setAuthError(payload));
  };
};