import { prefix } from './prefix';

export const passwordReset = email => {
  return fetch(prefix + '/password-reset', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  })
};