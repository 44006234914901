
import {
  RESERVATION_CREATE_ERROR,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_RESET,
  RESERVATION_SET_CURRENT,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_ERROR,
  RESERVATION_CLEAR_DELETED
} from '../action-types';

const _status = (s) => {
  return (['idle','creating','created','create','failed', 'delete-error'].includes(s) && s) || 'idle';
};

const initialState = {
  status: _status('idle'), // idle | creating | created | failed
  createSuccess: false,
  error: null,
  current: null,
  deleted: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESERVATION_SET_CURRENT:
      return {
        ...state,
        status: 'idle',
        current: action.payload.reservation
      }
    case RESERVATION_CREATE_SUCCESS:
      return {
        createSuccess: true, error: null, status: _status('created')
      }
    case RESERVATION_CREATE_ERROR:
      return {
        createSuccess: false, status: _status('failed'),
        error: action.payload.error
      };
    case RESERVATION_DELETE_SUCCESS:
      return {
        ...state,
        deleted: action.payload.reservation,
        error: null,
        status: _status('idle')
      };
    case RESERVATION_DELETE_ERROR:
      return {
        ...state,
        status: _status('delete-error'),
        error: action.payload.error
      };
    case RESERVATION_CLEAR_DELETED:
      return {
        ...state,
        deleted: null,
        error: null,
        status: _status('idle')
      }
    case RESERVATION_RESET:
      return {
        createSuccess: false,
        error: null,
        status: _status('idle')
      }
    default:
      return state;
  }
}
