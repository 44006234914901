
export const createState = (prevState, newState) => {
  return {
    ...prevState,
    ...newState
  };
};

export const createReducer = (initState, handlers) => {

  return (state = initState, action) => {

    const handler = handlers.find(h => h.type === action.type);

    if (!handler) {
      return state;
    }

    const states = [];
    for (const propName in handler) {
      const hasOwnProp = Object.prototype.hasOwnProperty.call(handler, propName);
      const fn = handler[propName];
      if (hasOwnProp && typeof fn === 'function') {
        states.push(fn(state, action));
      }
    }
    const _reduce = (acc, cur) => ({...acc, ...cur});
    return states.reduce(_reduce, {});
  }
};