
export const AUTH_SET_EMAIL = 'AUTH_SET_EMAIL';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_WRITE_TOKEN = 'AUTH_WRITE_TOKEN';
export const AUTH_READ_TOKEN = 'AUTH_READ_TOKEN';
export const AUTH_AUTHENTICATION_FAIL = 'AUTH_AUTHENTICATION_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_ERROR_CLEAR = 'AUTH_ERROR_CLEAR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_ID = 'AUTH_SET_ID';
export const AUTH_WRITE_ID = 'AUTH_WRITE_ID';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_SET_STATUS = 'AUTH_SET_STATUS';
export const AUTH_PASSWORD_RESET_SUCCESS = 'AUTH_PASSWORD_RESET_SUCCESS';
export const AUTH_PASSWORD_RESET_ERROR = 'AUTH_PASSWORD_RESET_ERROR';
export const AUTH_PASSWORD_CHANGE_SUCCESS = 'AUTH_PASSWORD_CHANGE_SUCCESS';
export const AUTH_PASSWORD_CHANGE_ERROR = 'AUTH_PASSWORD_CHANGE_ERROR';

export const CALENDAR_SET_CURRENT_DATE = 'CALENDAR_SET_CURRENT_DATE';
export const CALENDAR_CLEAR_CURRENT_DATE = 'CALENDAR_CLEAR_CURRENT_DATE';
export const CALENDAR_SET_VIEW = 'CALENDAR_SET_VIEW';
export const CALENDAR_TOGGLE_VIEW = 'CALENDAR_TOGGLE_VIEW';

export const INSTRUCTORS_SET = 'INSTRUCTORS_SET';
export const INSTRUCTOR_SET_ERROR = 'INSTRUCTOR_SET_ERROR';

export const NOTIFICATION_NOTIFY = 'NOTIFICATION_NOTIFY';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

export const PAYMENT_SET_CLIENT_SECRET = 'PAYMENT_SET_CLIENT_SECRET';
export const PAYMENT_SET_PROGRESS = 'PAYMENT_SET_PROGRESS';
export const PAYMENT_SET_CARD_HOLDER = 'PAYMENT_SET_CARD_HOLDER';
export const PAYMENT_CLEAR_PAYMENT_INTENT = 'PAYMENT_CLEAR_PAYMENT_INTENT';

export const POST_SET_POSTS = 'POST_SET_POSTS';
export const POST_SET_POST = 'POST_SET_POST';
export const POST_SET_STATUS = 'POST_SET_STATUS';
export const POST_SET_ERROR = 'POST_SET_ERROR';
export const POST_RESET_STATE = 'POST_RESET_STATE';

export const PROFILE_CREATE_PROFILE_SUCCESS = 'PROFILE_CREATE_PROFILE_SUCCESS';
export const PROFILE_CREATE_PROFILE_ERROR = 'PROFILE_CREATE_PROFILE_ERROR';
export const PROFILE_RESET_STATE = 'PROFILE_RESET_STATE';
export const PROFILE_SET = 'PROFILE_SET';
export const PROFILE_SET_BALANCE = 'PROFILE_SET_BALANCE';
export const PROFILE_SET_RESERVATIONS = 'PROFILE_SET_RESERVATIONS';
export const PROFILE_SET_STATUS = 'PROFILE_SET_STATUS';

export const USER_SET_USERS = 'USER_SET_USERS'

export const RESERVATION_CREATE_SUCCESS = 'RESERVATION_CREATE_SUCCESS';
export const RESERVATION_CREATE_ERROR = 'RESERVATION_CREATE_ERROR';
export const RESERVATION_RESET = 'RESERVATION_RESET';
export const RESERVATION_SET_CURRENT = 'RESERVATION_SET_CURRENT';
export const RESERVATION_DELETE_SUCCESS = 'RESERVATION_DELETE_SUCCESS';
export const RESERVATION_DELETE_ERROR = 'RESERVATION_DELETE_ERROR';
export const RESERVATION_CLEAR_DELETED = 'RESERVATION_CLEAR_DELETED';

export const ROOM_SET_ROOM = 'ROOM_SET_ROOM';
export const ROOM_SET_SPOT = 'ROOM_SET_SPOT';
export const ROOM_SET_MATRIX_VALUE = 'ROOM_SET_MATRIX_VALUE';
export const ROOM_INIT_MATRIX = 'ROOM_INIT_MATRIX';

export const SCHEDULE_SET = 'SCHEDULE_SET';
export const SCHEDULE_DRAIN_SESSIONS = 'SCHEDULE_DRAIN_SESSIONS';

export const SESSION_SET = 'SESSION_SET';
export const SESSION_SET_ERROR = 'SESSION_SET_ERROR';
export const SESSIONS_SET = 'SESSIONS_SET';
export const SESSION_SET_SPOT = 'SESSION_SET_SPOT';
export const SESSION_CREATING = 'SESSION_CREATING';
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS';
export const SESSION_DELETE_SUCCESS = 'SESSION_DELETE_SUCCESS';
export const SESSION_SUSPEND_SUCCESS = 'SESSION_SUSPEND_SUCCESS';
export const SESSION_SET_STATUS = 'SESSION_SET_STATUS';

export const WHITELIST_SET_WHITELIST = 'WHITELIST_SET_WHITELIST';
export const WHITELIST_SET_STATUS = 'WHITELIST_SET_STATUS';
