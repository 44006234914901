
import React from 'react';
import { Provider } from 'react-redux';
import Container from '@material-ui/core/Container';
import { SnackbarProvider } from 'notistack';
import clsx from 'clsx';
import CreateTheme from './theme';
import Navigation from './navigation';
import Router from './router';
import { Notifier } from './components/notifier';
import store from './redux/store';
import './webfontkit.css';
import './App.css';

const App = () => (
  <SnackbarProvider maxSnack={3}>
    <div className={clsx('background')}>
      <Container maxWidth='md' className={clsx('foreground')}>
        <Notifier />
        <Navigation />
        <Router />
      </Container>
    </div>
  </SnackbarProvider>
);

const ThemedApp = CreateTheme(App);

const AppProvider = () => (
  <Provider store={store}>
    <ThemedApp />
  </Provider>
);

export default AppProvider;
