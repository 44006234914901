import {USER_SET_USERS} from "../action-types";
import * as api from "../../api";
import {notifySuccess, notifyError} from "./notification";

export const setUsers = users => ({
    type: USER_SET_USERS,
    payload: { users }
});

export const fetchUsers = () => async (dispatch, getState) => {

    const state = getState();
    const {token} = state.auth;
    const response = await api.fetchUsers(token);

    if (response.ok) {
        const data = await response.json();
        dispatch(setUsers(data.users));
    } else {
        const data = await response.json();
        dispatch(notifyError(data.message));
    }
};

export const deleteUser = (user) => async (dispatch, getState) => {

    const state = getState();
    const {token} = state.auth;

    const response = await api.deleteUser(user, token);
    if (response.ok) {
        dispatch(notifySuccess('Delete successful'));
        dispatch(fetchUsers());
    } else {
        const data = await response.json();
        dispatch(notifyError(data.message));
    }
};
