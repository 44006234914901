
export const authenticate = async (username, password) => {

  const response = await fetch('/api/authenticate', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({ username, password })
  });

  if (!response.ok) {
    const json = await response.json();
    throw json;
  }

  const data = await response.json();
  return data;
};
