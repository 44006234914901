
import * as actionTypes from '../action-types';

export const getStatus = (s) => {

  const statuses = [
    'idle', 'loading', 'error',
    'creating', 'create-success', 'create-error',
    'delete-success', 'delete-error'
  ];

  if (statuses.includes(s)) {
    return s;
  }

  return 'idle';
};

const initialState = {
  whitelist: [],
  status: getStatus('idle')
};

export default function (state = initialState, action) {

  switch (action.type) {

    case actionTypes.WHITELIST_SET_WHITELIST:
      return {
        ...state,
        whitelist: action.payload.whitelist
      };
    case actionTypes.WHITELIST_SET_STATUS:
      return {
        ...state,
        status: getStatus(action.payload.status)
      };
    default:
      return state;
  }
}
