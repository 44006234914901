import {
  PAYMENT_SET_CLIENT_SECRET,
  PAYMENT_SET_PROGRESS,
  PAYMENT_SET_CARD_HOLDER,
  PAYMENT_CLEAR_PAYMENT_INTENT
} from '../action-types';

const createNewState = (oldState, newState) => {
  return {
    ...oldState,
    ...newState
  }
};

export const initialState = createNewState({
  clientSecret: null,
  apiKey: null,
  postal: {},
  progress: 'idle',
  cardHolderName: '',
  cardHolderEmail: '',
  cardHolderAddress: '',
  cardHolderCity: '',
  cardHolderPostalCode: '',
});

export default function (state = initialState, action) {
  switch (action.type) {
    case PAYMENT_SET_CLIENT_SECRET:
      return createNewState(state, {
        clientSecret: action.payload.clientSecret,
        apiKey: action.payload.apiKey
      });
    case PAYMENT_CLEAR_PAYMENT_INTENT:
      return createNewState(state, {
        clientSecret: null,
        apiKey: null,
        progress: 'idle',
        cardHolderName: '',
        cardHolderEmail: '',
        cardHolderAddress: '',
        cardHolderCity: '',
        cardHolderPostalCode: ''
      });
    case PAYMENT_SET_PROGRESS:
      return createNewState(state, {
        progress: action.payload.progress
      });
    case PAYMENT_SET_CARD_HOLDER:
      return createNewState(state,
        action.payload.cardHolder);
    default:
      return state;
  }
}
