
import { combineReducers } from 'redux';

import auth from './auth';
import calendar from './calendar';
import payment from './payment';
import profile from './profile';
import post  from './post';
import reservation from './reservation';
import session from './session';
import schedule from './schedule';
import instructor from './instructor';
import net from './net';
import notification  from './notification';
import room from './room';
import whitelist from './whitelist';
import user from './user';

export default combineReducers({
  auth,
  calendar,
  instructor,
  payment,
  post,
  profile,
  reservation,
  schedule,
  session,
  net,
  notification,
  room,
  whitelist,
  user,
});
