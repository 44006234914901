/**
 * 'Session' refers to a 'training session',
 * not as you might think, a http session.
 */
import {
  SESSION_SET,
  SESSION_SET_ERROR,
  SESSIONS_SET,
  SESSION_SET_SPOT,
  SESSION_SET_STATUS,
  SESSION_CREATE_SUCCESS,
  SESSION_UPDATE_SUCCESS,
  SESSION_DELETE_SUCCESS,
  SESSION_SUSPEND_SUCCESS
} from '../action-types';

import { createState, createReducer } from './base';

export const statuses = [
  'idle', 'creating', 'updating',
  'deleting', 'loading', 'suspending',
  'create-success', 'create-error',
  'update-success', 'update-error',
  'delete-success', 'delete-error',
  'suspend-success', 'suspend-error'
];

export const getStatus = status => {

  const sts = statuses;
  const idx = sts.indexOf(status);
  return idx > -1 ? sts[idx] : sts[0];
};

const initialState = {
  error: null,
  sessions: [],
  status: getStatus('idle')
};

export default createReducer(initialState, [{
  type: SESSION_SET_ERROR,
  h: (state, action) => createState(state, {
    error: action.payload.error,
    status: getStatus(action.payload.status),
  })
}, {
  type: SESSION_SET,
  h: (state, action) => {
    return createState(state, {
      session: action.payload.session
    })
  }
}, {
  type: SESSIONS_SET,
  h: (state, action) => {
    return createState(state, {
      sessions: action.payload.sessions
    })
  }
}, {
  type: SESSION_SET_SPOT,
  h: (state, action) => createState(state, {
    spot: action.payload.spot
  })
}, {
  type: SESSION_CREATE_SUCCESS,
  h: (state, action) => createState(state, {
    status: getStatus('create-success'),
    contentLocation: action.payload.contentLocation
  })
}, {
  type: SESSION_UPDATE_SUCCESS,
  h: (state, action) => createState(state, {
    status: getStatus('update-success'),
    contentLocation: action.payload.contentLocation
  })
}, {
  type: SESSION_DELETE_SUCCESS,
  h: (state) => createState(state, {
    status: getStatus('delete-success')
  })
}, {
  type: SESSION_SUSPEND_SUCCESS,
  h: (state) => createState(state, {
    status: getStatus('suspend-success')
  })
}, {
  type: SESSION_SET_STATUS,
  h: (state, action) => {
    return createState(state, {
      status: getStatus(action.payload.status)
    });
  }
}]);
