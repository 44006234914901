import { prefix } from './prefix';

export const deleteWhitelisting = (id, token) => {
  return fetch(prefix + '/whitelist/' + id, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    }
  });
};