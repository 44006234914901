import * as api from '../../api';
import * as actionTypes from '../action-types';

export const setWhitelist = (whitelist) => {
  return {
    type: actionTypes.WHITELIST_SET_WHITELIST,
    payload: { whitelist }
  }
};

export const setWhitelistStatus = status => {
  return {
    type: actionTypes.WHITELIST_SET_STATUS,
    payload: { status }
  }
};

export const fetchWhitelist = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const response = await api.fetchWhitelist(token);
    if (response.ok) {
      const json = await response.json();
      return dispatch(setWhitelist(json.whitelist));
    }
  };
};

export const deleteWhitelisting = record => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const response = await api.deleteWhitelisting(
      record.id, token);
    if(response.ok) {
      dispatch(setWhitelistStatus('delete-success'));
      return;
    }
    dispatch(setWhitelistStatus('delete-error'));
  };
};

export const createWhitelisting = (record) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    dispatch(setWhitelistStatus('creating'));
    const response = await api.createWhitelisting(token, record);
    if (response.ok) {
      dispatch(setWhitelistStatus('create-success'));
      return;
    }
    dispatch(setWhitelistStatus('create-error'));
  };
};
