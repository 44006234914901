
import * as api from '../../api';
import {
  SCHEDULE_SET,
  SCHEDULE_DRAIN_SESSIONS
} from '../action-types';

export const setSchedule = (schedule) => {
  return {
    type: SCHEDULE_SET,
    payload: { schedule }
  }
};

export const scheduleDrainSessions = () => ({
  type: SCHEDULE_DRAIN_SESSIONS
});

export const fetchSchedule = () => {

  return async dispatch => {

    const schedule = await api.fetchSchedule();
    dispatch(setSchedule(schedule));
  };
};

/**
 * fetchScheduleByDate
 * @param {*} date the base date
 * @param {*} limit days
 */
export const fetchScheduleByDate = (date, limit = 1) => {

  if (!(date instanceof Date)) {
    return setSchedule(null);
  }

  return async dispatch => {
    dispatch(scheduleDrainSessions());
    const schedule = await api.fetchScheduleByDate(date, limit);
    setTimeout(() => dispatch(setSchedule(schedule)), 333);
  };
};