
import {
  PROFILE_CREATE_PROFILE_ERROR,
  PROFILE_RESET_STATE,
  PROFILE_SET,
  PROFILE_SET_BALANCE,
  PROFILE_SET_RESERVATIONS,
  PROFILE_SET_STATUS
} from '../action-types';

const getStatus = (status) => {

  const statuses = ['idle', 'create-profile',
    'create-profile-success', 'create-profile-error',
    'confirm-profile', 'confirm-profile-success',
    'confirm-profile-error'];

  const idx = statuses.indexOf(status);

  if (idx > -1) {
    return statuses[idx];
  }
  return statuses[0];
};

const initialState = {
  status: getStatus('idle'),
  error: null,
  profile: null,
  balance: 0,
  reservations: []
};

export default function (state = initialState, action) {

  switch (action.type) {

    case PROFILE_CREATE_PROFILE_ERROR:
      return {
        error: action.payload.error
      };

    case PROFILE_SET:
      return {
        ...state,
        profile: action.payload.profile
      };

    case PROFILE_SET_BALANCE:
      return {
        ...state,
        balance: action.payload.balance
      };

    case PROFILE_SET_RESERVATIONS:
      return {
        ...state,
        reservations: action.payload.reservations
      };

    case PROFILE_SET_STATUS:
      return {
        ...state,
        status: getStatus(action.payload.status)
      };

    case PROFILE_RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
