
import * as api from '../../api';
import {
  PAYMENT_SET_CLIENT_SECRET,
  PAYMENT_CLEAR_PAYMENT_INTENT,
  PAYMENT_SET_PROGRESS,
  PAYMENT_SET_CARD_HOLDER
} from '../action-types';

export const setPaymentClientSecret = ({ clientSecret, apiKey }) => {
  return {
    type: PAYMENT_SET_CLIENT_SECRET,
    payload: { clientSecret, apiKey }
  }
};

export const createPaymentIntent = (amount) => {

  return async (dispatch, getState) => {

    const state = getState();
    const response = await api.createPaymentIntent(state.auth.token, amount);
    const data = await response.json();
    dispatch(setPaymentClientSecret({
      clientSecret: data.client_secret,
      apiKey: data.public_key
    }));
  }
};

export const clearPaymentIntent = () => ({
  type: PAYMENT_CLEAR_PAYMENT_INTENT
});

export const postalSearch = (code) => {

  return async dispatch => {
    const data = await api.postalSearch(code);
    if(data.result?.location) {
      dispatch(setPaymentCardHolder({
        cardHolderCity: data.result.location
      }));
    } else {
      dispatch(setPaymentCardHolder({
        cardHolderCity: undefined
      }));
    }
  };
};

export const setPaymentProgress = progress => {
  return {
    type: PAYMENT_SET_PROGRESS,
    payload: { progress }
  }
};

export const setPaymentCardHolder = cardHolder => {
  return {
    type: PAYMENT_SET_CARD_HOLDER,
    payload: { cardHolder }
  }
};
