
export const createPaymentIntent = (token, amount) => {

  return fetch('/api/payment-intent', {
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      id: 'training_session',
      amount
    })
  });
};
