
import * as api from '../../api';
import * as actionTypes from '../action-types';
import { logger } from '../../utils/log';

export const setPosts = posts => {
  return {
    type: actionTypes.POST_SET_POSTS,
    payload: { posts }
  }
};

export const setPostError = error => {
  return {
    type: actionTypes.POST_SET_ERROR,
    payload: { error }
  }
};

export const setPostStatus = status => {
  return {
    type: actionTypes.POST_SET_STATUS,
    payload: { status }
  }
};

export const resetPostState = () => {
  return {
    type: actionTypes.POST_RESET_STATE
  }
};

export const setPost = post => {

  return {
    type: actionTypes.POST_SET_POST,
    payload: { post }
  };
};

export const fetchPost = ofKind => {

  return async (dispatch, getState) => {

    dispatch(setPostStatus('loading'));

    const state = getState();
    const { token } = state.auth;
    const response = await api.fetchPost(token, ofKind);
    if (response.ok) {
      const json = await response.json();
      dispatch(setPostStatus('idle'));
      return dispatch(setPost(json.post));
    }
    logger.error('fetchPost', response.error);
    dispatch(setPostStatus('error'));
    dispatch(setPostError(response.error));
  };
};

export const fetchPosts = ofKind => {

  return async (dispatch, getState) => {

    dispatch(setPostStatus('loading'));

    const state = getState();
    const { token } = state.auth;
    const response = await api.fetchPosts(token, ofKind);
    if (response.ok) {
      const json = await response.json();
      dispatch(setPostStatus('idle'));
      return dispatch(setPosts(json.posts));
    }
    logger.error('fetchPosts', response.error);
    dispatch(setPostStatus('error'));
  };
};

export const createPost = post => {
  return async (dispatch, getState) => {

    dispatch(setPostError());

    const state = getState();
    const { token } = state.auth;
    const response = await api.createPost(token, post);
    if (response.ok) {
      return dispatch(setPostStatus('create-success'));
    }
    const data = await response.json();
    logger.error('createPost failed', data.message);
    dispatch(setPostStatus('create-error'));
    dispatch(setPostError(data));
  };
};