
import { prefix } from './prefix';

export const fetchInstructors = (token) => {

  return fetch(prefix + '/instructors', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    }
  });
};