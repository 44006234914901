
import { prefix } from './prefix';

/**
 * fetchSession
 * Fetch a session by sessionId
 * @param {*} sessionId
 * @returns sessions
 */
export const fetchSession = async (sessionId) => {
  
  return fetch(prefix + '/sessions/' + sessionId);
};

export const fetchSessionsWithReservationsByDateRange = async (token, from, to) => {

  const url = `${prefix}/sessions?from=${from.toISOString()}&to=${to.toISOString()}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      'authorization': 'Bearer ' + token
    }
  });
};

export const createSession = async (token, session) => {

  return fetch(prefix + '/sessions', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    },
    body: JSON.stringify(session)
  });
};

export const updateSession = (token, session) => {
  
  const attrs = {
    title: session.title,
    description: session.description,
    at: session.at,
    duration: session.duration,
    instructor_id: session.instructor_id
  };
  
  return fetch(prefix + '/sessions/' + session.id, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    },
    body: JSON.stringify(attrs)
  });
};

export const deleteSession = (token, sessionId) => {
  return fetch(prefix + '/sessions/' + sessionId, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    }
  });
};

export const suspendSession = (token, sessionId) => {
  return fetch(prefix + '/sessions/suspend/' + sessionId, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      status: 'suspended'
    })
  });
};