
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Router, Location } from '@reach/router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Spinning } from './components/spinning';

const Home = React.lazy(() => import('./routes/home'));
const Benefits = React.lazy(() => import('./routes/benefits'));
const Login = React.lazy(() => import('./routes/login'));
const CreateProfile = React.lazy(() => import('./routes/create-profile'));
const PasswordRecovery = React.lazy(() => import('./routes/password-recovery'));
const Logout = React.lazy(() => import('./routes/logout'));
const Session = React.lazy(() => import('./routes/session'));
const Payment = React.lazy(() => import('./routes/payment'));
const Profile = React.lazy(() => import('./routes/profile'));
const ConfirmProfile = React.lazy(() => import('./routes/confirm-profile'));
const Splash = React.lazy(() => import('./routes/splash'));
const Trainer = React.lazy(() => import('./routes/trainer/trainer'));
const TrainerSessions = React.lazy(() => import('./routes/trainer/sessions'));
const TrainerSession = React.lazy(() => import('./routes/trainer/session'));
const TrainerSessionEdit = React.lazy(() => import('./routes/trainer/session-edit'));
const TrainerSessionNew = React.lazy(() => import('./routes/trainer/session-new'));
const TrainerWhitelistRoute = React.lazy(() => import('./routes/trainer/whitelist'));
const TrainerUsersRoute = React.lazy(() => import('./routes/trainer/user'));
const TrainerPostsRoute = React.lazy(() => import('./routes/trainer/posts'));
const TrainerRoomRoute = React.lazy(() => import('./routes/trainer/room'));
const TermsRoute = React.lazy(() => import('./routes/terms'));

const NotFound = (props) => {

  return (
    <div style={{
      textAlign: 'center',
      margin: '50px 0 0',
      fontSize: '1.5em',
      color: 'red'
    }}>[404] Page &apos;{props.uri}&apos; not found</div>
  )
};

NotFound.propTypes = {
  uri: PropTypes.string
};

const Routes = () => {
  return (
    <Suspense fallback={<Spinning text='Loading routes ...' />}>
      <Location>
        {({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} classNames="fade" timeout={200}>
              <Router location={location} className="router">
                <Home className='page' path='/booking' />
                <Benefits path='/benefits' />
                <Login path='/login' />
                <Logout path='/logout' />
                <CreateProfile path='/create-profile' />
                <PasswordRecovery path='/password-recovery' />
                <Splash path='/' />
                <Session className='page' path='/session/:sessionId' />
                <Payment path='/payment/:sessionId' />
                <Profile path='/profile' />
                <ConfirmProfile path='/confirm/:token' />
                <TermsRoute path='/terms' />
                <Trainer path='/trainer'>
                  <TrainerSessions path='/' />
                  <TrainerSession path='session/:sessionId' />
                  <TrainerSessionNew path='session-new' />
                  <TrainerSessionEdit path='session/edit/:sessionId' />
                  <TrainerPostsRoute path='posts' />
                  <TrainerRoomRoute path='room' />
                  <TrainerWhitelistRoute path='whitelist' />
                  <TrainerUsersRoute path='users' />
                </Trainer>
                <NotFound default />
              </Router>
            </CSSTransition>
          </TransitionGroup>
        )}
      </Location>
    </Suspense>
  );
};

export default Routes;
