
import { NOTIFICATION_NOTIFY, NOTIFICATION_REMOVE } from '../action-types';

const randomKey = () => (Math.round(Math.random() * Date.now() * 1000).toString(36));

const createOptions = (v) => {
  return {
    variant: v,
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top'
    }
  }
};

export const notifySuccess = message => {
  return {
    type: NOTIFICATION_NOTIFY,
    payload: {
      message,
      options: createOptions('success'),
      key: randomKey()
    }
  }
};

export const notifyWarning = message => {
  return {
    type: NOTIFICATION_NOTIFY,
    payload: {
      message,
      options: createOptions('warning'),
      key: randomKey()
    }
  }
};

export const notifyError = message => {
  return {
    type: NOTIFICATION_NOTIFY,
    payload: {
      message,
      options: createOptions('error'),
      key: randomKey()
    }
  }
};
 
export const notifyInfo = message => {
  return {
    type: NOTIFICATION_NOTIFY,
    payload: {
      message,
      options: createOptions('info'),
      key: randomKey()
    }
  }
};

export const removeNotification = key => {
  return {
    type: NOTIFICATION_REMOVE,
    payload: { key }
  }
};
