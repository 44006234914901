
import { prefix } from './prefix';

export const fetchRoom = async (token) => {

  return fetch(prefix + '/rooms/1', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};