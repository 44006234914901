import {USER_SET_USERS} from "../action-types";

const initialState = {
    users: [],
};

export default function (state = initialState, action) {

    switch (action.type) {
        case USER_SET_USERS:
            return {
                ...state,
                users: action.payload.users
            };
        default:
            return state;
    }
}
