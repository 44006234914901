import * as api from '../../api';

import {
  INSTRUCTORS_SET,
  INSTRUCTOR_SET_ERROR
} from '../action-types';

export const setInstructorError = error => {
  return {
    type: INSTRUCTOR_SET_ERROR,
    payload: { error }
  }
};

export const setInstructors = instructors => {
  return {
    type: INSTRUCTORS_SET,
    payload: { instructors }
  }
};

export const fetchInstructors = () => {

  return async (dispatch, getState) => {

    const state = getState();
    const { token } = state.auth;
    const response = await api.fetchInstructors(token);

    if (response.ok) {
      const data = await response.json();
      dispatch(setInstructors(data.instructors));
    }
    else {
      const data = await response.json();
      dispatch(setInstructorError(data));
    }
  };
};