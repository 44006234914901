
export const createProfile = async (profile) => {

  return fetch('/api/profile', {
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(profile)
  });
};