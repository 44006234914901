
import { NOTIFICATION_NOTIFY, NOTIFICATION_REMOVE } from '../action-types';

const initialState = {
  messages: []
};

export default function (state = initialState, action) {

  switch (action.type) {

    case NOTIFICATION_NOTIFY:
      return {
        messages: state.messages.concat(action.payload)
      };

    case NOTIFICATION_REMOVE:
      return {
        messages: state.messages.filter(m => m.key !== action.payload.key)
      };

    default:
      return state
  }
}