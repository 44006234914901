import React from 'react';
import { ThemeProvider, useTheme } from '@material-ui/core/styles';

export default (Component) => (props) => {

  const theme = Object.assign({}, useTheme(), {
    overrides: {
      palette: {
        text: {
          primary: '#000160'
        },
        background: {
          body: 'white',
          default: 'white'
        }
      }
    }
  });
  return <ThemeProvider theme={theme}><Component {...props} /></ThemeProvider>
};
