
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotification } from '../redux/actions';

let displayed = [];

export const Notifier = () => {

  const dispatch = useDispatch();
  const messages = useSelector(store => store.notification.messages);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    messages.forEach(({ message, key, options = {} }) => {

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (_, _key) => {
          dispatch(removeNotification(_key));
          displayed = displayed.filter(k => k !== _key);
        }
      });

      displayed.push(key);

    });
  }, [messages, dispatch, enqueueSnackbar]);
  return <div style={{ display: 'none' }}></div>
};
