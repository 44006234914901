
import { prefix } from './prefix';

export const fetchPost = async (token, ofKind) => {

  return fetch(prefix + '/posts-last-of-kind?kind=' + ofKind, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + token
    }
  });
};